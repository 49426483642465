import { ItemsGroup } from '../../components/itemGroup/itemGroup';
import { useEffect, useState } from 'react';
import { searchFetch } from '../../lib/api/rest/search';
import { FetchResponse } from '../../components/fetchStatus/fetchStatus';
import { useFetchStatus } from '../../lib/utils/hooks/useFetchStatus';
import { FetchStatus } from '../../lib/enums/fetchStatuses';
import { useRecoilState } from 'recoil';
import { searchItemsCountAtom } from '../../recoil/atoms/searchItemsCount';

interface SearchContainerProps {
    search: string,
	count: number,
	page: number
}

export const SearchContainer = ({ search, count, page }: SearchContainerProps) => {
	const [items, setItems] = useState([]);
	const [fetchStatus, setFetchStatus] = useFetchStatus();
	const [, setSearchItemsCount] = useRecoilState(searchItemsCountAtom);

	useEffect(() => {
		search && searchFetch({ query: search, mode: 1, count, offset: (page - 1) * count }).then((res) => {
			const { items, count } = res?.data || {};

			setSearchItemsCount(count);
			setFetchStatus(items ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);

			setItems(items);
		}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
	}, [search, page, count]);

	return (
		<FetchResponse status={ fetchStatus } error='Мы не смогли найти ничего по вашему запросу'>
			<ItemsGroup items={ items } />
		</FetchResponse>
	);
};