import { Modal } from '../modal/modal';
import style from './style.module.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { purchaseModalAtom } from '../../recoil/atoms/purchaseModal';
import { FetchResponse } from '../fetchStatus/fetchStatus';
import { ImagesSlider } from '../imagesSlider/imagesSlider';
import { usePurchase } from '../../lib/utils/hooks/usePurchase';
import { Typography } from '../typography/typography';
import { Select } from '../select/select';
import { SelectOption } from '../select/selectOption/selectOption';
import { sizeSelector } from '../../recoil/selectors';
import { Button } from '../button/button';
import cs from 'classnames';
import { ScaleImage } from '../scaleImage/scaleImage';
import { Promt } from '../promt/promt';
import { useScaleImage } from '../scaleImage/hooks/useScale';
import { sexStatus } from '../../lib/utils/statuses';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';
import { Label } from '../label/label';

export const PurchaseModal = () => {
	const [purchaseModal, setPurchaseModal] = useRecoilState(purchaseModalAtom);

	const [showScale, toggleScale] = useScaleImage();

	const { sizeByID } = useRecoilValue(sizeSelector);
	const { item, chooseImage, image, fetchStatus, addItemToBasket, setSize, setItem } = usePurchase(purchaseModal.id, closeModal);
	const { title, itemID, price, size, images, description, sizes, sex, brands, is_discount } = item;

	function closeModal() {
		setPurchaseModal({ visible: false, id: undefined });
		setItem({ description: undefined, is_discount: false, price: 0, sizes: undefined, id: undefined, title: '', images: [], brands: [], sex: 0, categories: [] });
	}

	return (
		<Modal className={ style.modal } title={ title } subtitle={ itemID ? `ID ${itemID}` : undefined } visible={ purchaseModal.visible } onClose={ closeModal }>
			<ScaleImage activeImage={ image } images={ images } visible={ showScale } onClose={ toggleScale }/>
			<FetchResponse status={ fetchStatus } error='Произошла непредвиденная ошибка, пожалуйста, повторите позже'>
				<div className={ style.modalPurchase }>
					<ImagesSlider className={ style.itemImages } images={ images } activeImageID={ image } onChoose={ chooseImage } onClick={ toggleScale } />
					<div className={ style.priceBlock }>
						<Typography className={ style.field } type='h4' tag='span'>{ `${price} ₽` }</Typography>
						<ToggleVisibility visible={ is_discount }>
							<Label label='Скидка 30%' className={ style.discountLabel } />
						</ToggleVisibility>
					</div>
					{ sizes && <Select className={ cs(style.param, style.field) } onChange={ setSize } placeholder='Размер' value={ size } closeOnSelect>
						{ sizes.map((sizeIDIteration) => {
							const sizeObject = sizeByID(sizeIDIteration);

							return <SelectOption value={ sizeIDIteration } key={ sizeIDIteration }>{ sizeObject?.text }</SelectOption>;
						}) }
					</Select> }
					<Promt text='Для выбора цвета или модели нажмите на нужное изображение' />
					<Button className={ cs(style.param, style.field) } theme='primary' onClick={ addItemToBasket }>
            			Добавить в корзину
					</Button>
					<div className={ cs(style.description, style.field) }>
						<Typography type='h5' tag='h3'>Описание</Typography>
						<ul className={ style.characteristics }>
							<li>{ `Бренд: ${brands?.join(', ') ?? ''}` }</li>
							<li>{ `Пол: ${sexStatus[sex] ?? ''}` }</li>
						</ul>
						<Typography type='body' className={ style.descriptionText }>
							{ description }
						</Typography>
					</div>
				</div>
			</FetchResponse>
		</Modal>
	);
};
