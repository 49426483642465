import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { usePagination } from '../../../lib/utils/hooks/usePagination';
import { AdminTable } from '../adminTable/adminTable';
import { IOrderAdminFetchResponse, ordersAdminFetch } from '../../../lib/api/rest/admin/orders';
import { ModalOrder } from '../modalOrder/modalOrder';
import { isLoggedAtom } from '../../../recoil/atoms';
import { useURL } from '../../../lib/utils/hooks/useURL';
import { useHistory, useLocation } from 'react-router';

export const OrdersAdminTable = () => {
	const { search } = useLocation();
	const history = useHistory();

	const [isLogged] = useRecoilState(isLoggedAtom);
	const [ordersCount, setOrdersCount] = useState(0);
	const [orders, setOrders] = useState([]);
	const [ordersData, setOrdersData] = useState([]);

	const [orderModal, setOrderModal] = useState<{ visible: boolean, id: string }>({
		visible: false,
		id: ''
	});

	const { pageCount, filter } = usePagination(10, ordersCount, 1);
	const { getParams, addParam } = useURL(search, history);

	useEffect(() => {
		addParam('page')(1);
	}, []);

	const { page } = getParams() || {};

	useEffect(() => {
	  isLogged && ordersAdminFetch({ count: filter, offset: (page as number - 1) * filter }).then((res) => {
		  const { orders, count } = res?.data || {};

			setOrdersData(orders);

			const ordersArray = orders?.map((order: IOrderAdminFetchResponse) => {
				const { customer: { email, phone }, totalprice, created_at, ispayed, id } = order;

				return {
				  id,
					data: [
						{
							title: 'Email',
							label: email
						},
						{
							title: 'Телефон',
							label: phone
						},
						{
							title: 'Сумма',
							label: `${totalprice} ₽`
						},
						{
							title: 'Дата заказа',
							label: new Date(created_at).toLocaleDateString()
						},
						{
							title: 'Оплачен',
							label: ispayed ? 'Да' : 'Нет'
						},
						{
							label: 'Подробнее',
							action: () => {
								setOrderModal({ id, visible: true });
							}
						}
					]
				};
			});

			setOrdersCount(count);
			setOrders(ordersArray);
		});
	}, [isLogged, +page]);

	const columns = ['Email', 'Телефон', 'Сумма', 'Дата заказа', 'Оплачен'];
	const closeModalOrder = () => setOrderModal({ id: '', visible: false });

	const { visible, id } = orderModal || {};
	const modalOrder = ordersData?.find((order: IOrderAdminFetchResponse) => order.id === id);

	const handleChangePage = (page: number) => addParam('page')(page);

	return (
	  <>
			<ModalOrder order={ modalOrder } onClose={ closeModalOrder } visible={ visible } />
			<AdminTable data={ orders } page={ +page } pageCount={ pageCount } handleChangePage={ handleChangePage } columns={ columns }/>
		</>
	);
};

OrdersAdminTable.defaultProps = {
	page: 1,
	onChoosePage: undefined,
	pageCount: undefined
};
