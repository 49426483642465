import { StyleHTMLAttributes } from 'react';
import cs from 'classnames';
import style from './style.module.scss';
import { ItemCard } from '../itemCard/itemCard';

interface IItem {
  id: number,
  name: string,
  image: string,
  price: number,
  is_discount: boolean
}

interface ItemGroupProps {
  className?: string,
  style?: StyleHTMLAttributes<any>,
  items: IItem[]
}

export const ItemsGroup = ({ className, style: styles, items }: ItemGroupProps): JSX.Element => {
	return (
		<div style={ styles } className={ cs(style.itemGroup, className) }>
			{ items?.map(({ id, image, is_discount, name: title, price }) => {
			  return <ItemCard className={ style.card } badge={ is_discount ? { label: '-30%', color: 'var(--red-color)' } : undefined } itemID={ id } key={ id } title={ title } price={ price + ' ₽' } image={ image } />;
			}) }
		</div>
	);
};

ItemsGroup.defaultProps = {
	className: null,
	style: null
};
