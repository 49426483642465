import { Breadcrumbs } from '../../components/breadcrumbs/breadcrumbs';
import { Link } from '../../components/link/link';
import { Typography } from '../../components/typography/typography';
import style from './style.module.scss';
import discountJSON from '../../static/discount.json';
import { withRouter } from 'react-router-dom';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const DiscountItem = withRouter(({ match }: any) => {
	const discountID = +match.params.discountID;

	const { title, description } = discountJSON.find((discount) => discount.id === discountID) || {};

	return (
		<MainTemplate>
			<Breadcrumbs>
				<Link href='/discount' type='inner' theme='default' size='medium' className={ style.mainBreadcrumbText }>Акции</Link>
				<Typography type='body'>{ title }</Typography>
			</Breadcrumbs>
			<Typography type='body' className={ style.discountText }>
				{ description }
			</Typography>
		</MainTemplate>
	);
});
