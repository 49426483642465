import style from './style.module.scss';
import { Typography } from '../../components/typography/typography';
import { Input } from '../../components/input/input';
import { InputRadio } from '../../components/inputRadio/inputRadio';
import { useEffect, useState } from 'react';
import { Tabs } from '../../components/tabs/tabs';
import { Tab } from '../../components/tab/tab';
import { Icon } from '../../components/icon/icon';
import { ReactComponent as VisaSVG } from '../../images/visa.svg';
import { ReactComponent as MastercardSVG } from '../../images/mastercard.svg';
import { ReactComponent as MirSVG } from '../../images/mir.svg';
import { ReactComponent as PaypalSVG } from '../../images/paypal.svg';
import { PriceBlock } from '../../components/priceBlock/priceBlock';
import { Button } from '../../components/button/button';
import { ChosenItem } from '../../components/chosenItem';
import cs from 'classnames';
import { ChosenItems } from '../../components/chosenItems/chosenItems';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom } from '../../recoil/atoms';
import { IValue, Select } from '../../components/select/select';
import { SelectOption } from '../../components/select/selectOption/selectOption';
import { basketSelector, sizeSelector } from '../../recoil/selectors';
import { useBasket } from '../../lib/utils/hooks/useBasket';
import { Link } from '../../components/link/link';
import { useForm } from 'react-awesome-form';
import { RegExp } from '../../lib/enums/regExp';
import { ToggleVisibility } from '../../components/toggleVisibility/toggleVisibility';
import { Promt } from '../../components/promt/promt';
import { orderCreate } from '../../lib/api/rest/order/order';
import { useHistory } from 'react-router';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Ordering = (): JSX.Element => {
	const [deliveryMethod, setDeliveryMethod] = useState(1);
	const [paymentType, setPaymentType] = useState<IValue | undefined>(undefined);
	const [deliveryRegion, setDeliveryRegion] = useState<IValue | undefined>(1);
	const { basket, reset } = useBasket();
	const history = useHistory();
	const [userData] = useRecoilState(userAtom);
	const { sizeByID } = useRecoilValue(sizeSelector);
	const { totalPrice, discount, hasDiscountItems, hasCategories } = useRecoilValue(basketSelector);

	const deliveryForm = useForm();
	const emailDelivery = deliveryForm.addInput('email', true, '', { required: true, regExp: RegExp.EMAIL });
	const mobileDelivery = deliveryForm.addInput('mobile', true, '', { required: true, regExp: RegExp.MOBILE });
	const streetDelivery = deliveryForm.addInput('street', true, '', { required: true });
	const flatDelivery = deliveryForm.addInput('flat',true, '', { required: true });
	const houseDelivery = deliveryForm.addInput('house', true, '', { required: true });
	const deliveryTime = deliveryForm.addInput('deliveryTime', false, '', { required: true });

	const pickupForm = useForm();
	const emailPickup = pickupForm.addInput('email', true, '', { required: true, regExp: RegExp.EMAIL });
	const mobilePickup = pickupForm.addInput('mobile', true, '', { required: true, regExp: RegExp.MOBILE });
	const pickupDate = pickupForm.addInput('pickupDate', false, '', { required: true });

	const sendingForm = useForm();
	const emailSending = sendingForm.addInput('email', true, '', { required: true, regExp: RegExp.EMAIL });
	const mobileSending = sendingForm.addInput('mobile', true, '', { required: true, regExp: RegExp.MOBILE });
	const streetSending = sendingForm.addInput('street', true, '', { required: true });
	const flatSending = sendingForm.addInput('flat',true, '', { required: true });
	const houseSending = sendingForm.addInput('house', true, '', { required: true });
	const postIndex = sendingForm.addInput('postIndex', false, '', { required: true });
	const city = sendingForm.addInput('city', false, '', { required: true });


	useEffect(() => {
		if (userData.email) {
			emailPickup.setValue(userData.email ?? '');
			emailSending.setValue(userData.email ?? '');
			emailDelivery.setValue(userData.email ?? '');
		}
		if (userData.mobile) {
			mobileSending.setValue(userData.mobile ?? '');
			mobileDelivery.setValue(userData.mobile ?? '');
			mobilePickup.setValue(userData.mobile ?? '');
		}
		if (userData.house) {
			houseSending.setValue(userData.house ?? '');
			houseDelivery.setValue(userData.house ?? '');
		}
		if (userData.flat) {
			flatSending.setValue(userData.flat ?? '');
			flatDelivery.setValue(userData.flat ?? '');
		}
		if (userData.street) {
			streetDelivery.setValue(userData.street ?? '');
			streetSending.setValue(userData.street ?? '');
		}
	}, [userData]);

	const requestDataFactory = (deliveryType: number) => {
	  const defaultObject = {
			items: basket,
			method: paymentType,
			deliverymethod: deliveryMethod
		};

		switch (deliveryType) {
		case 1: {
			return {
				customer: {
					phone: `+${mobileDelivery.value()}`,
					email: emailDelivery.value(),
					street: streetDelivery.value(),
					flat: flatDelivery.value(),
					house: houseDelivery.value()
				},
				delivery_time: deliveryTime.value(),
				region: deliveryRegion,
				...defaultObject
			};
		}
		case 2: {
			return {
				customer: {
					phone: `+${mobilePickup.value()}`,
					email: emailPickup.value()
				},
				pickup_date: pickupDate.value(),
				...defaultObject
			};
		}
		case 3: {
			return {
				customer: {
					phone: `+${mobileSending.value()}`,
					email: emailSending.value(),
					street: streetSending.value(),
					flat: flatSending.value(),
					house: houseSending.value(),
					city: city.value(),
					index: postIndex.value()
				},
				...defaultObject
			};
		}
		default: {
			return {};
		}
		}
	};

	const createOrder = () => {
		switch (deliveryMethod) {
		case 1: {
			deliveryForm.validate();

			if (deliveryForm.errors.length > 0) return null;

			break;
		}
		case 2: {
			pickupForm.validate();

			if (pickupForm.errors.length > 0) return null;

			break;
		}
		case 3: {
			sendingForm.validate();

			if (sendingForm.errors.length > 0) return null;

			break;
		}
		default: break;
		}

		orderCreate(requestDataFactory(deliveryMethod)).then((res) => {
			const confirmation = res.data?.confirmationResponse;
			const orderID = res.data?.order_id;

			if (orderID) reset();

			if (confirmation && orderID) {
				return window.location.replace(confirmation);
			} else if (orderID) {
				return history.push(`/order/${orderID}`);
			}
		});
	};

	const discountObject = discount(paymentType);
	const discountPrice = discountObject?.sum ?? 0;
	const discountType = discountObject?.type;
	const withCommission = paymentType === 2 ? Math.ceil((discountPrice ?? 0) * 1.045) : discountPrice;

	return (
		<MainTemplate>
			<Typography type='h3' tag='h1'>Оформление</Typography>
			<div className={ style.container }>
				<div className={ cs(style.delivery, style.section) }>
					<Typography type='h4' tag='h2' className={ style.sectionTitle }>Доставка</Typography>
					<div className={ style.orderingOptions }>
						<div className={ style.orderingType }>
							Доставка
							<InputRadio onChange={ setDeliveryMethod } className={ style.orderingOption } value={ 1 } currentValue={ deliveryMethod } />
						</div>
						<div className={ style.orderingType }>
							Самовывоз
							<InputRadio onChange={ setDeliveryMethod } className={ style.orderingOption } value={ 2 } currentValue={ deliveryMethod } />
						</div>
						<div className={ style.orderingType }>
							Отправка
							<InputRadio onChange={ setDeliveryMethod } className={ style.orderingOption } value={ 3 } currentValue={ deliveryMethod } />
						</div>
					</div>
					<Tabs currentValue={ deliveryMethod }>
						<Tab value={ 1 }>
							<Typography type='h4' tag='h2' className={ style.sectionTitle }>Данные для доставки</Typography>
							<form className={ style.fields }>
								<div className={ style.fieldGroup }>
									<Input { ...streetDelivery.handlers } error={ streetDelivery.error } errorMsg='Пожалуйста, укажите улицу' value={ streetDelivery.value() } className={ style.field } type='text' placeholder='Улица *' />
									<Input { ...flatDelivery.handlers } value={ flatDelivery.value() } error={ flatDelivery.error } errorMsg='Пожалуйста, укажите квартиру' className={ style.field } type='text' placeholder='Квартира *' />
									<Input { ...houseDelivery.handlers } value={ houseDelivery.value() } className={ style.field } error={ houseDelivery.error } errorMsg='Пожалуйста, укажите дом' type='text' placeholder='Дом/корпус *' />
								</div>
								<div className={ style.fieldGroup }>
									<Input { ...mobileDelivery.handlers } value={ mobileDelivery.value() } error={ mobileDelivery.error } errorMsg='Пожалуйста, укажите номер телефона' className={ style.field } type='text' placeholder='Номер телефона *' />
									<Input { ...deliveryTime.handlers } error={ deliveryTime.error } errorMsg='Пожалуйста, укажите время доставки' className={ style.field } type='text' placeholder='Время доставки *' />
									<Input { ...emailDelivery.handlers } value={ emailDelivery.value() } error={ emailDelivery.error } errorMsg='Пожалуйста, укажите почту' className={ style.field } type='text' placeholder='Email *' />
								</div>
							</form>
							<Select className={ style.selectField } onChange={ setDeliveryRegion } value={ deliveryRegion } placeholder='Регион доставки' closeOnSelect>
								<SelectOption value={ 1 }>Доставка по Москве до адреса</SelectOption>
								<SelectOption value={ 2 }>Доставка по Московской области</SelectOption>
							</Select>
						</Tab>
						<Tab value={ 2 }>
							<Typography type='h4' tag='h2' className={ style.sectionTitle }>Данные для самовывоза</Typography>
							<form className={ style.fields }>
								<div className={ style.fieldGroup }>
									<Input { ...mobilePickup.handlers } value={ mobilePickup.value() } error={ mobilePickup.error } errorMsg='Пожалуйста, укажите почту' className={ style.field } type='text' placeholder='Номер телефона *' />
									<Input { ...emailPickup.handlers } value={ emailPickup.value() } error={ emailPickup.error } errorMsg='Пожалуйста, укажите почту' className={ style.field } type='text' placeholder='Email *' />
									<Input { ...pickupDate.handlers } error={ pickupDate.error } errorMsg='Пожалуйста, укажите дату самовывоза' className={ style.field } type='text' placeholder='Дата самовывоза' />
								</div>
							</form>
						</Tab>
						<Tab value={ 3 }>
							<Typography type='h4' tag='h2' className={ style.sectionTitle }>Данные для отправки</Typography>
							<form className={ style.fields }>
								<div className={ style.fieldGroup }>
									<Input { ...streetSending.handlers } value={ streetSending.value() } error={ streetSending.error } errorMsg='Пожалуйста, укажите улицу' className={ style.field } type='text' placeholder='Улица *' />
									<Input { ...flatSending.handlers } value={ flatSending.value() } error={ flatSending.error } errorMsg='Пожалуйста, укажите квартиру' className={ style.field } type='text' placeholder='Квартира *' />
									<Input { ...houseSending.handlers } value={ houseSending.value() } error={ houseSending.error } errorMsg='Пожалуйста, укажите дом' className={ style.field } type='text' placeholder='Дом/корпус *' />
								</div>
								<div className={ style.fieldGroup }>
									<Input { ...mobileSending.handlers } value={ mobileSending.value() } error={ mobileSending.error } errorMsg='Пожалуйста, укажите телефон' className={ style.field } type='text' placeholder='Номер телефона *' />
									<Input { ...emailSending.handlers } value={ emailSending.value() } error={ emailSending.error } errorMsg='Пожалуйста, укажите почта' className={ style.field } type='text' placeholder='Email *' />
								</div>
								<div className={ style.fieldGroup }>
									<Input { ...postIndex.handlers } error={ postIndex.error } errorMsg='Пожалуйста, укажите почтовый индекс' className={ style.field } type='text' placeholder='Индекс *' />
									<Input { ...city.handlers } error={ city.error } errorMsg='Пожалуйста, укажите населенный пункт' className={ style.field } type='text' placeholder='Населенный пункт *' />
								</div>
							</form>
						</Tab>
					</Tabs>
					<Typography type='h4' tag='h2' className={ style.sectionTitle }>Ваш заказ</Typography>
					<ChosenItems>
						{ basket?.map((basketItem) => {
							const { count, id, imageURL, size, name, price, uniqueID, is_discount } = basketItem;

							return (
								<ChosenItem key={ uniqueID } isDiscount={ is_discount } id={ id } className={ style.item } price={ `${price} ₽` } title={ name } subtitle={ `Артикул ${id}` } image={ imageURL } characteristics={
									[{
										option: 'Размер',
										value: sizeByID(size)?.text
									},
									{
										option: 'Количество',
										value: count
									}]
								}
								/>
							);
						}) }
					</ChosenItems>
				</div>
				<div className={ cs(style.payment, style.section) }>
					<Typography type='h4' tag='h2' className={ style.sectionTitle }>Оплата</Typography>
					<div className={ style.paymentIcons }>
						<Icon className={ style.paymentIcon } image={ VisaSVG } size='small' />
						<Icon className={ style.paymentIcon } image={ MastercardSVG } size='small' />
						<Icon className={ style.paymentIcon } image={ MirSVG } size='small' />
						<Icon className={ style.paymentIcon } image={ PaypalSVG } size='small' />
					</div>
					<PriceBlock className={ style.priceBlock } price={ [
					 	{ label: 'Сумма', price: `${totalPrice} ₽` },
					 	{ label: 'С учетом скидки', price: `${discountPrice} ₽` },
					 	{ label: 'Без доставки', price: `${discountPrice} ₽` },
					 	{ label: 'Комиссия банкинг системы', price: paymentType && [2].includes(+paymentType) ? withCommission - discountPrice + ' ₽' : null },
					 	{ label: 'Итого', price: `${withCommission} ₽`, highlight: true }
					 ] }
					/>
					<ToggleVisibility visible={ totalPrice < 4500 && ![2].includes(+(paymentType ?? 0)) }>
						<Promt text='Скидка 11% при оплате картой' />
					</ToggleVisibility>
					<ToggleVisibility visible={ totalPrice < 4500 && ![1].includes(discountType ?? -1) }>
						<Promt text={ `Для скидки 21% необходимо взять товар еще на ${Math.ceil(4500 - totalPrice)} рублей` } />
					</ToggleVisibility>
					<Select className={ style.priceField } onChange={ setPaymentType } value={ paymentType } placeholder='Метод оплаты' closeOnSelect>
						<ToggleVisibility visible={ !hasDiscountItems || hasCategories([1200, 1201, 1205]) }>
							<SelectOption value={ 1 }>Оплата заказа при получении (Москва и МО)</SelectOption>
						</ToggleVisibility>
						<SelectOption value={ 2 }>Онлайн оплата картой</SelectOption>
						<SelectOption value={ 3 }>Онлайн плата переводом</SelectOption>
						<SelectOption value={ 4 }>Наложенный платеж</SelectOption>
						<SelectOption value={ 5 }>Оплатить 50%</SelectOption>
						<SelectOption value={ 6 }>Безопасная сделка (Авито и Юла)</SelectOption>
					</Select>
					<Button theme='primary' className={ style.button } onClick={ createOrder }>Оформить</Button>
					<span className={ style.policyText }>
            			Оплачивая товар, вы соглашаетесь с <Link type='inner' href='/policy' theme='default' size='medium' className={ style.highlight }>Политикой конфиденциальности</Link> и <Link type='inner' href='/policy' theme='default' size='medium' className={ style.highlight }>Пользовательским соглашением</Link>
					</span>
				</div>
			</div>
		</MainTemplate>
	);
};
