import style from './style.module.scss';
import { Typography } from '../../typography/typography';
import { IconButton } from '../../iconButton/iconButton';
import { ReactComponent as PlusSVG } from '../../../images/plus.svg';
import { ReactComponent as MinusSVG } from '../../../images/minus.svg';
import { ReactComponent as DeleteSVG } from '../../../images/close.svg';
import cs from 'classnames';
import { Link } from '../../link/link';
import { limitText } from '../../../lib/utils/limitText';
import { Label } from '../../label/label';
import { ToggleVisibility } from '../../toggleVisibility/toggleVisibility';

interface Characteristics {
  option: string,
  value: any
}

export interface ChosenItemProps {
  	image: string | undefined,
  	title: string | undefined,
  	id?: number,
	isDiscount?: boolean
  	subtitle: string,
  	characteristics: Characteristics[],
  	count?: number,
  	price?: string,
  	className?: string,
  	onDelete?: () => void,
  	onIncrease?: () => void,
  	onDecrease?: () => void
}

export const DesktopChosenItem = ({ image, title, id, subtitle, characteristics, count, isDiscount, price, className, onDelete, onDecrease, onIncrease }: ChosenItemProps): JSX.Element => {
	let characteristicID = 0;

	return (
		<div className={ cs(style.chosenItem, className) }>
			<img className={ style.image } src={ image } alt='item' />
			<div className={ style.itemBody }>
				<div className={ style.head }>
					<Link size='medium' href={ `/item/${id}` } type='inner' theme='default'>
						<Typography type='h5' tag='h3' className={ style.title }>{ limitText(title, 20) }</Typography>
					</Link>
					<Typography type='body' tag='span'>{ subtitle }</Typography>
					<ToggleVisibility visible={ !!isDiscount }>
						<Label label='Скидка 30%' className={ style.label } />
					</ToggleVisibility>
				</div>
				<div className={ style.itemDescription }>
					{ characteristics && <ul className={ style.characteristics }>
						{ characteristics.map(({ option, value }) => {
							characteristicID++;

							if (value) return <li key={ characteristicID } className={ style.characteristic }>
								<strong>{ option }:</strong> { value }</li>;
						}) }
					</ul> }
					{ count != null && <div className={ style.itemOptions }>
						{ onDecrease && <IconButton className={ style.subtractIcon } size='medium' onClick={ onDecrease } icon={ MinusSVG }/> }
						<Typography type='h5' tag='span' className={ style.option }>{ count }</Typography>
						{ onIncrease && <IconButton className={ style.addIcon } size='medium' onClick={ onIncrease } icon={ PlusSVG }/> }
					</div> }
					{ price && <Typography type='h4' tag='span' className={ style.price }>{ price }</Typography> }
					{ onDelete && <IconButton onClick={ onDelete } className={ style.deleteIcon } icon={ DeleteSVG }/> }
				</div>
			</div>
		</div>
	);
};

DesktopChosenItem.defaultProps = {
	className: null,
	onDelete: null,
	count: null,
	price: null,
	isDiscount: false
};
