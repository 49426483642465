import { Drawer } from '../../drawer/drawer';
import style from '../../header/mobile/style.module.scss';
import globalStyle from '../styles/style.module.scss';
import { Input } from '../../input/input';
import { CategoriesGroup } from '../../categoriesGroup/categoriesGroup';
import { Tabs } from '../../tabs/tabs';
import { Tab } from '../../tab/tab';
import { Nav } from '../../navigation/nav';
import { SubcategoriesGroup } from '../../subcategoriesGroup/subcategoriesGroup';
import { useState } from 'react';
import { MenuNavProps } from '../menuNav';
import { useMenuNav } from '../hooks/useMenuNav';
import { NavLink } from '../../navLink/navLink';

export const MenuNavMobile = ({ visible, onClose, children }: MenuNavProps) => {
	const { setCategoryID, search, chosenCategoryID, categories } = useMenuNav();
	const [subcategoryMenuVisibility, setSubcategoryMenuVisibility] = useState<boolean>(false);

	const chooseCategory = (value: any) => {
		setCategoryID(value);
		setSubcategoryMenuVisibility(true);
	};

	const handleSubcategoryClick = () => {
		setSubcategoryMenuVisibility(false);
		onClose();
	};

	const closeSubcategoryMenu = () => {
		setCategoryID(undefined);
		setSubcategoryMenuVisibility(false);
	};

	return (
		<>
			<Drawer visible={ visible } onClose={ onClose } position='left'>
				<>{ children }</>
				<div className={ globalStyle.inputBox }>
					<Input { ...search.handlers } className={ globalStyle.search } type='text' placeholder='Поиск по категориям' />
				</div>
				<Nav orientation='vertical' className={ globalStyle.menu }>
					<NavLink to='/items' className={ globalStyle.menuText } onClick={ onClose } label={ 'Все товары' }/>
					<NavLink to='/items/1201' className={ globalStyle.menuText } onClick={ onClose } label={ 'JI-C&C-S' }/>
					<NavLink to='/items?sex=1' className={ globalStyle.menuText } onClick={ onClose } label={ 'Мужское' }/>
					<NavLink to='/items?sex=2' className={ globalStyle.menuText } onClick={ onClose } label={ 'Женское' }/>
					<NavLink to='/items?sex=3' className={ globalStyle.menuText } onClick={ onClose } label={ 'Унисекс' }/>
					<NavLink to='/items/1200' className={ globalStyle.menuText } onClick={ onClose } label={ 'Sale' }/>
					<NavLink to='/items?date=1' className={ globalStyle.menuText } onClick={ onClose } label='Новинки'/>
					<NavLink to='/items/1203' className={ globalStyle.menuText } onClick={ onClose } label='Люкс'/>
				</Nav>
				<CategoriesGroup search={ search.value() } chosenID={ chosenCategoryID } onClick={ chooseCategory } />
			</Drawer>
			<Drawer position='left' onClose={ closeSubcategoryMenu } visible={ subcategoryMenuVisibility }>
				<Tabs currentValue={ chosenCategoryID }>
					{ categories()?.map((category) => {
						const { id, subcategories } = category;

						return (
							<Tab key={ id } value={ id }>
								<Nav key={ id } orientation='vertical'>
									<SubcategoriesGroup subcategories={ subcategories } onLinkClick={ handleSubcategoryClick } linkClassName={ globalStyle.subcategoryLink } />
								</Nav>
							</Tab>
						);
					}) }
				</Tabs>
			</Drawer>
		</>
	);
};

MenuNavMobile.defaultProps = {
	children: undefined
};
