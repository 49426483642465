import { useEffect, useRef, useState } from 'react';
import { IValue } from '../../../components/select/select';
import { useFetchStatus } from './useFetchStatus';
import { InitItem } from '../../../pages/item/item';
import { useBasket } from './useBasket';
import { itemFetch } from '../../api';
import { FetchStatus } from '../../enums/fetchStatuses';
import { normalizeText } from '../normalizeText';
import { BasketItem } from '../../storage/adapters/basket.adapter';

export const usePurchase = (id: number | undefined, onBasketAdd?: () => void) => {
	const [item, setItem] = useState<InitItem>({
		id: 0,
		price: 0,
		description: '',
		images: [],
		sizes: [],
		title: '',
		is_discount: false,
		brands: [],
		sex: 0,
		uniqueID: '',
		categories: []
	});

	const [image, setImage] = useState<number>(0);
	const [size, setSize] = useState<IValue | undefined>();
	const [fetchStatus, setFetchStatus] = useFetchStatus();

	const { add: addToBasket } = useBasket();

	useEffect(() => {
		if (id) {
			setFetchStatus(FetchStatus.PENDING);

			itemFetch({ id }).then((res) => {
				const { id, name, categories, images, price, description, sizes, is_discount, brands_names: brands, sex, uniqueID } = res.data.items[0] || {};

				let imageID = 0;

				const imagesArray = images.map((imageSrc: string) => ({
					image: imageSrc,
					payload: {
						id: imageID++
					}
				}));

				setFetchStatus(res.data ? FetchStatus.SUCCESS : FetchStatus.NOT_FOUND);

				setItem({
					price, id, uniqueID, title: name, description: normalizeText(description), images: imagesArray, sizes, is_discount, brands, sex, categories
				});
			}).catch(() => setFetchStatus(FetchStatus.NOT_FOUND));
		}
	}, [id]);

	const chooseImage = ({ id }: { id: number }) => setImage(id);

	const addItemToBasket = () => {
		const { id, images, title, price, is_discount, categories } = item;

		const categoriesNumber = categories.map((category) => category.categoryID);

		const basketItem: BasketItem = {
			id: id as number, image, count: 1, is_discount,
			size: size ? size : 0,
			imageURL: images?.[image]?.image,
			name: title,
			price: +price,
			categories: categoriesNumber
		};

		addToBasket(basketItem);
		onBasketAdd && onBasketAdd();
	};

	const { title, id: itemID, price, images, description, sizes, brands, sex, categories, is_discount } = item;

	return {
	  item: {
			title,
			itemID,
			price,
			size,
			images,
			description,
			sizes,
			brands,
			sex,
			categories,
		  	is_discount
		},
		chooseImage,
		image,
		fetchStatus,
		addItemToBasket,
		setSize,
		setItem
	};
};
