import style from './style.module.scss';
import { Typography } from '../../components/typography/typography';
import { ChosenItem } from '../../components/chosenItem';
import { useBasket } from '../../lib/utils/hooks/useBasket';
import { useRecoilValue } from 'recoil';
import { basketSelector, sizeSelector } from '../../recoil/selectors';
import { ButtonLink } from '../../components/buttonLink/buttonLink';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';
import { limitText } from '../../lib/utils/limitText';
import { PriceBlock } from '../../components/priceBlock/priceBlock';
import { Promt } from '../../components/promt/promt';
import { ToggleVisibility } from '../../components/toggleVisibility/toggleVisibility';

export const Basket = (): JSX.Element => {
	const { update, remove, basket } = useBasket();
	const { totalPrice } = useRecoilValue(basketSelector);
	const { sizeByID } = useRecoilValue(sizeSelector);

	const changeItemCount = (uniqueID: string | undefined, newCount: number) => update(uniqueID, { count: newCount });

	return (
		<MainTemplate>
			<Typography type='h3' tag='h1'>Корзина</Typography>
			<div className={ style.basketContainer }>
				<div className={ style.basketItems }>
					{ basket?.map((item) => {
						const { name, price, count, id, imageURL, uniqueID, is_discount } = item;

						const size = sizeByID(item?.size)?.text;

						const onIncrease = () => changeItemCount(uniqueID, count + 1);
						const onDecrease = () => count - 1 > 0 && changeItemCount(uniqueID, count - 1);
						const onDelete = () => remove(uniqueID);

						return (
							<ChosenItem id={ id } key={ uniqueID } isDiscount={ is_discount } onDelete={ onDelete } className={ style.item } title={ name } subtitle={ `Артикул ${id}` } price={ `${price} ₽` } image={ imageURL } count={ count } onIncrease={ onIncrease } onDecrease={ onDecrease } characteristics={
								[
									{
										option: 'Размер',
										value: size
									}]
							}
							/>
						);
					})
					}
				</div>
				<div className={ style.price }>
					<Typography type='h5' tag='h3'>Итого</Typography>
					<PriceBlock className={ style.priceGroup } price={ [
						{ label: 'Сумма', price: `${limitText(totalPrice, 7)} ₽` },
						{ label: 'Без учета скидки', price: `${limitText(totalPrice, 7)} ₽` },
						{ label: 'Всего', price: `${limitText(totalPrice, 7)} ₽`, highlight: true }
					] }
					/>
					<ToggleVisibility visible={ totalPrice < 4500 }>
						<Promt text={ `Для скидки 21% необходимо взять товар еще на ${Math.ceil(4500 - totalPrice)} рублей` } />
					</ToggleVisibility>
					<ButtonLink className={ style.button } type='inner' href='/ordering' theme='primary'>Оформить</ButtonLink>
				</div>
			</div>
		</MainTemplate>
	);
};
