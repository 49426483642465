import { ReactComponent as HeaderOpenSVG } from '../../../images/headerOpen.svg';
import { ReactComponent as UserSVG } from '../../../images/user.svg';
import { ReactComponent as SearchSVG } from '../../../images/search.svg';
import { ReactComponent as BucketSVG } from '../../../images/bucket.svg';
import { IconButton } from '../../iconButton/iconButton';
import { Logo } from '../../logo/logo';
import { Nav } from '../../navigation/nav';
import { NavLink } from '../../navLink/navLink';
import { Badge } from '../../badge/badge';
import style from './style.module.scss';
import globalHeaderStyle from '../styles/style.module.scss';
import { useHeader } from '../../../lib/utils/hooks/useHeader';
import { useRecoilValue } from 'recoil';
import { basketSelector } from '../../../recoil/selectors';
import { forwardRef, MutableRefObject, RefObject, useRef } from 'react';
import { Link } from '../../link/link';
import cs from 'classnames';
import { MenuNavDesktop } from '../../menuNav/menuNavDesktop/menuNavDesktop';
import { SearchMenu } from '../../searchMenu/searchMenu';
import { useSearch } from '../../searchMenu/hooks/useSearch';
import { Icon } from '../../icon/icon';

export interface HeaderProps {
  	dark?: boolean,
	fixed: boolean
}

export const DesktopHeader = forwardRef(({ dark, fixed }: HeaderProps, ref): JSX.Element => {
	const { isVisibleMenu, handleMenuVisibility, closeMenuVisibility } = useHeader();
	const { length: basketLength } = useRecoilValue(basketSelector);
	const { visibleSearch, toggleSearchVisibility, closeSearch } = useSearch();
	const searchIcon = useRef();
	const searchContainer = useRef() as MutableRefObject<any>;

	return (
	  <>
			<MenuNavDesktop visible={ isVisibleMenu } onClose={ closeMenuVisibility } />
			<header ref={ ref as RefObject<any> } className={ cs(globalHeaderStyle.header, style.header, { [globalHeaderStyle.headerFixed]: fixed }) } data-dark={ dark || fixed }>
				<div className={ style.headerMainIconGroup }>
					<IconButton className={ style.menuIcon } icon={ HeaderOpenSVG } onClick={ handleMenuVisibility } />
					<Logo className={ style.headerLogo }/>
				</div>
				<Nav orientation='horizontal'>
					<NavLink className={ cs(globalHeaderStyle.navLink, style.navLink) } to='/discount' label='Акции' />
					<NavLink className={ cs(globalHeaderStyle.navLink, style.navLink) } to='/reviews' label='Отзывы' />
					<NavLink className={ cs(globalHeaderStyle.navLink, style.navLink) } to='/quality' label='Качество' />
					<NavLink className={ cs(globalHeaderStyle.navLink, style.navLink) } to='/delivery' label='Доставка' />
					<NavLink className={ cs(globalHeaderStyle.navLink, style.navLink) } to='/items/1200' label='Sale' />
				</Nav>
				<div className={ style.headerIconGroup }>
					<div className={ style.headerSearch } ref={ searchContainer }>
						<IconButton ref={ searchIcon } onClick={ toggleSearchVisibility } className={ globalHeaderStyle.headerIcon } icon={ SearchSVG } />
					</div>
					<SearchMenu controllerEl={ searchIcon } onClose={ closeSearch } refEl={ searchIcon } visible={ visibleSearch } />
					<Link href='/profile' type='inner' theme='default' className={ globalHeaderStyle.headerIcon }>
						<Icon image={ UserSVG } className={ globalHeaderStyle.icon } />
					</Link>
					<Badge text={ basketLength }>
						<Link href='/basket' type='inner' theme='default' className={ globalHeaderStyle.headerIcon }>
							<Icon image={ BucketSVG } className={ globalHeaderStyle.icon } />
						</Link>
					</Badge>
				</div>
			</header>
		</>
	);
});

DesktopHeader.displayName = 'DesktopHeader';

DesktopHeader.defaultProps = {
	dark: false
};
