import style from './style.module.scss';
import { Section } from '../../components/section/section';
import { Typography } from '../../components/typography/typography';
import { Point } from '../../components/point/point';
import imgBox from '../../images/why/fMveBTz2qWw.png';
import imgPayment from '../../images/paymentImage.svg';
import { MainTemplate } from '../../components/mainTemplate/mainTemplate';

export const Delivery = () => {
	return (
		<MainTemplate>
			<Section title='Доставка и оплата' >
				<Typography type='body'>
					{ `В связи с участившимися случаями игнорирования звонков курьера по приезду на место заказа, недобропорядочными покупателями, просим оплачивать доставку, перед тем как курьер выезжает на доставку. \n\n Мы выполняем доставку любого товара по Москве и Московской области, службой доставки. Доставка в регионы осуществляется с помощью почты России или любой удобной для Вас ТК.
            \n Так же Вы можете самостоятельно забрать Вашу покупку, в нашем шоу-руме по адресу:
            \n
            1. г. Москва, 1-я Владимирская 20 а.
            \n
            2. г. Коломна ул. Октябрьской революции 368` }
				</Typography>
				<div>
					<Point className={ style.point } title='Доставка' description={ 'По Москве: \n\n 1. До адреса по Москве в пределах МКАД – от 350 рублей. \n 2. По Московской области - от 500 рублей \n 3. Точная сумма рассчитывается индивидуально по адресу вручения \n\n Доставка ТК: \n\n 1. Доставка до ТК или почты России - БЕСПЛАТНО. \n 2. Средняя стоимость доставки ТК составляет 250-700 руб, в зависимости от веса и объема. Более подробные расчеты можно получить на сайте интересующей Вас ТК. \n 3. Отправляем любой удобной для Вас ТК (Почта, СДЭК, ПЭК, Деловые Линии и т.д.)' } image={ imgBox }/>
					<Point className={ style.point } title='Оплата' description={ 'Оплата наличными: \n\n 1. Оплата заказа наличными курьеру возможна в г. Москва, в г. Коломна (максимальная сумма заказа для оплаты курьеру наличными составляет 20.000 руб и 8 товаров). \n 2. Обращаем Ваше внимание! Оплата доставки производиться в любом случае, независимо от того приобретаете Вы товар, или нет. \n\n Онлайн-оплата: \n\n 1. Вы всегда можете оплатить заказа банковской картой или электронными деньгами. Оплата заказов производиться моментально (24/7). Обращаем Ваше внимание что компания "Factory Corp" не получает данные Вашей карты. \n 2. Так же можно оплатить онлайн банковским переводом (номер уточняйте у менеджера) \n\n Наложенный платеж: \n\n 1. Отправка наложенным платежом производиться с 50% предоплатой + оплатой услуг почты либо ТК. Комиссию за услугу наложенный платеж, оплачивает покупатель (8-12% от общей суммы заказа). \n 2. Отправка посылок наложенным платежом осуществляется почтой России, а так же СДЭК.' } image={ imgPayment }/>
				</div>
				<div>
					<Typography type='h3' tag='h2' className={ style.title }>Время доставки</Typography>
					<Typography type='body'>
						{ 'Внимание! Неправильно указанный номер телефона, неточный или неполный адрес могут привести к дополнительной задержке! Пожалуйста, внимательно проверяйте ваши персональные данные при регистрации и оформлении заказа.Конфиденциальность ваших регистрационных данных гарантируется. \n\n Доставка выполняется ежедневно с 08:00 до 24:00 часов. Время осуществления доставки зависит от времени размещения заказа и наличия товара на складе: если заказ подтвержден менеджером, товар может быть доставлен на следующий рабочий день между 08:00 и 24:00. \n\n Внимание! Компания оставляет за собой право осуществить доставку в течении 1-4 рабочих дней, с момента обработки Вашего заказа (в праздничные дни срок доставки может быть увеличен до 6 дней).' }
					</Typography>
				</div>
				<div>
					<Typography type='h3' tag='h2' className={ style.title }>Правила</Typography>
					<Typography type='body'>
						{ 'При доставке вам будут переданы все необходимые документы на покупку: товарный, кассовый чеки. \n\n Внимание! Просим вас помнить, что все технические параметры и потребительские свойства приобретаемого товара вам следует уточнять у нашего менеджера до момента покупки товара. В обязанности работников Службы доставки не входит осуществление консультаций и комментариев относительно потребительских свойств товара. При необходимости инсталляции приобретаемого в нашем магазине товара вам необходимо сообщить об этом нашему менеджеру. При доставке Вам заказанного товара проверяйте комплектность доставленного товара, работоспособность товара, соответствие доставленного товара описанию на нашем сайте, также проверьте товар на наличие механических повреждений \n\n Внимание! В нашей компании, так же как и в любой другой компании работают в первую очередь люди, которым свойственно ошибаться. В связи с этим убедительная просьба, помните, что все мы люди и иногда случаются ошибки, будьте, пожалуйста, терпимее.' }
					</Typography>
				</div>
			</Section>
		</MainTemplate>
	);
};
