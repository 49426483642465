import style from './style.module.scss';
import { Logo } from '../../logo/logo';
import { Typography } from '../../typography/typography';

import { FooterLinks } from '../footerLinks/footerLinks';
import { SocialLinks } from '../footerSocialLinks/socialLinks';
import { FooterAbout } from '../footerAbout/footerAbout';
import { Icon } from '../../icon/icon';
import { ReactComponent as VisaSVG } from '../../../images/visa.svg';
import { ReactComponent as MastercardSVG } from '../../../images/mastercard.svg';
import { ReactComponent as MirSVG } from '../../../images/mir.svg';
import { ReactComponent as PaypalSVG } from '../../../images/paypal.svg';
import { FooterPayment } from '../footerPayment/footerPayment';

export const FooterDesktop = (): JSX.Element => {
	return (
		<footer className={ style.footer }>
			<div className={ style.body }>
				<div className={ style.about }>
					<Logo className={ style.logo } />
					<FooterAbout />
					<div className={ style.socialBlock }>
						<SocialLinks className={ style.socialLink }/>
					</div>
				</div>
				<div className={ style.footerSectionGroup }>
					<FooterLinks sectionClassName={ style.footerSection } titleClassName={ style.title } />
				</div>
			</div>
			<div className={ style.copyright }>
				<Typography type='body'>
          2021 – Оптово-розничный Маркетплейс © НЕДОРОГОКУПИЛИ.
				</Typography>
				<FooterPayment />
			</div>
		</footer>
	);
};
