import style from './style.module.scss';
import { Input } from '../input/input';
import { Link } from '../link/link';
import { Menu } from '../menu/menu';
import { MutableRefObject, useEffect, useState } from 'react';
import { useInput } from 'react-awesome-form';
import debounce from 'lodash.debounce';
import { searchFetch } from '../../lib/api/rest/search';
import cs from 'classnames';
import { ToggleVisibility } from '../toggleVisibility/toggleVisibility';

interface SearchMenuProps {
  onClose: () => void,
  refEl: MutableRefObject<any>,
  controllerEl: MutableRefObject<any>,
  visible: boolean
}

export const SearchMenu = ({ onClose, refEl, visible, controllerEl }: SearchMenuProps) => {
	const searchInput = useInput(true, '');
	const searchOnInput = debounce((e: any) => searchInput.handlers.onInput(e), 500);
	const [result, setResult] = useState([]);

	useEffect(() => {
		let isMounted = true;

		if (searchInput.value()) {
			searchFetch({ query: searchInput.value() }).then((res) => {
				isMounted && setResult(res?.data?.items ?? []);
			});
		}

		return () => {
			isMounted = false;
		};
	}, [searchInput.value()]);

	return (
		<Menu controllerRef={ controllerEl } className={ style.searchMenu } onClose={ onClose } refEl={ refEl } visible={ visible }>
			<Input type='text' onInput={ searchOnInput } placeholder='Поиск'/>
			<div className={ style.results }>
				<ToggleVisibility visible={ result.length > 0 }>
					<Link className={ cs(style.resultLink, style.showMore) } href={ `/search/${searchInput.value()}` } type='inner' size='medium' theme='default'>Показать похожие</Link>
				</ToggleVisibility>
				{ result?.map?.((result, index) => {
				  const { id, name } = result || {};
					return <Link key={ index } className={ style.resultLink } href={ `/item/${id}` } type='inner' size='medium' theme='default'>{ `${name} | ID${id}` }</Link>;
				}) }
			</div>
		</Menu>
	);
};
